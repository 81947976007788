export default function () {
  return {
    confirm: async (code) => {
      const response = await useGetMethod(
        "/api/uc/qr/confirm",
        { code },
        { raw: true }
      );

      if (response.code == "FAILURE" && response.status !== 200) {
        throw response.msg || "WRONG_TV_CODE";
      }

      return response;
    },
  };
}
